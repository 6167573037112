import React, { useEffect, useState } from "react";
import client from "../hooks/Data.js";
import { useParams } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import SliderAdd from "../components/SliderAdd";
import ButtonPrimary from "../components/ButtonPrimary";
import useCookie from "../hooks/Cookie";
import { WinnerUrl } from "../hooks/Env";
import SetStat from "../hooks/SetStat.js";

const Place = () => {
  const [annonce, setAnnonce] = useState({});
  const [isWinner, setIsWinner] = useState(false);
  const [ref, setRef] = useState();
  // decrémentation de limite
  const [image, setImage] = useState(true);

  const [loading, setLoading] = useState(false);

  const [medias, setMedias] = useState();

  const [addEl, setAddEl] = useState();
  //var addEl  // element de l'annonce

  const [destination, setDestination] = useState();


  function setDestUrl() {
    // if (annonce !== {} && annonce != null ) {
    const url = WinnerUrl + ref + "%20Annonce%20%3A%20" + encodeURIComponent(annonce.description);
    isWinner ? setDestination(url) : setDestination(annonce.url_des);
    // }
    console.log(destination, ref);
  }

  useEffect(() => {
    setLoading(true);

    if (document.readyState === "complete") {
      setLoading(false);
    }
  }, [loading]);

  window.onload = (event) => {
    setLoading(false);
    console.log("La page est complètement chargée");

  };


  const onDestClick = () => {
    client.post("/gagner", {
      idAnncs: annonce.id_anncs,
      code_event: annonce.code_event,
      ref: ref,
    });
  
    window.open(destination, "_blank", "noopener noreferrer");
  };


  const [msgToPlace, setMsgToPlace] = useState("");
  var {idevent, giftplace, other}  = useParams();

  const [qrString, setQrString] = useState( idevent ? "/select/aleatoire/annonce/" + idevent : "select/aleatoire/annonce");

  
  // useEffect(() => {
    
  //   if (idevent != undefined && idevent != "") {
  //     setQrString("/select/aleatoire/annonce/" + idevent);
  //   }
    
  // }, [idevent]);

  useEffect(() => {
    (async function () {
      if (
        !useCookie.ifCookie("annonce") ||
        useCookie.getCookie("annonce") == ""
      ) {
        client
          .get(qrString)
          .then((res) => {
            const data = res.data.data;

            setAnnonce(JSON.parse(JSON.stringify(data)));
            console.log("ann :" + JSON.stringify(data));
          })
          .catch((err) => {
            console.error(err);
            return err;
          });
      } else {
        client
          .get(qrString)
          .then((res) => {
            const data = res.data.data;

            setAnnonce(JSON.parse(JSON.stringify(data)));
          })
          .catch((err) => {
            console.error(err);
          });
      }
    })();
  }, []);

  useEffect(() => {
    (async function () {
      // est-ce une annonce gagnante ?
      if (annonce.type_anncs === 1) {
        // initalisation de la réf s'il gagne | generation de ref

        //préciser que c'est une annonce gagnante
        setIsWinner(true);

        await client
          .get("generateur/ref")
          .then((res) => {
            // const d = res.data.data.ref;
            setRef(res.data.data.ref);
            //setDestination(WinnerUrl + ref + "%20Annonce%20%3A%20" + encodeURIComponent(annonce.description));
            setDestUrl(res.data.data.ref)
            useCookie.setCookie("annonce", annonce);
          })
          .catch((err) => console.error(err));
      } else {
             setDestination(annonce.url_des);
      }

 

    })();
  }, [annonce.type_anncs]);

  // format d'affichage de l'annonce
  useEffect(() => {
    (async function () {

      // setTimeout(() => {
      setMedias(annonce.media);

      SetStat(annonce.code_event, annonce.id_anncs);
     
      // set the add type
        setAddEl(
          <img
            src={process.env.REACT_APP_API_BASE_URL + "/" + medias[0].url_med}
            alt="annonce"
            className="scangif p-0 img-fluid"
            onClick={onDestClick}
          />
        );

      // }, 5000);
    })();
  }, [ref, annonce.media, medias]);

  return (
    <div className="text-center">
      {loading ? (
        <ClipLoader loading={loading} size={80} />
      ) : (
        <div className="card bg-transparent text-center border-0">
          <div className="card-header bg-transparent border-0">
            <h3 className="title-s-1 my-3 change-color">Clique sur l'image !</h3>
          </div>

          <div className="mb-2 d-flex flex-column justify-content-center align-items-center">
            <div className=" text-center">
              {addEl}
            </div>
          </div>
          <div className="text-center bg-transparent mt-4 border-0">
            {/* <p> {annonce.description}</p> */}
          </div>

          <ButtonPrimary toUrl="/scanpage" text="Scanne à nouveau" actions />
        </div> 
      )}
    </div>
  );
};

export default Place;
